import { useAuth } from '@cocoonspace/shared/domains/auth/hooks/use-auth.hook'
import type { GetStaticProps } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { SvgGoogle } from '~/components/icons//font-awesome/brands/google'
import { Button } from '~/components/ui/button'
import { BASE_I18N } from '~/config/base-i18n'

export default () => {
	const { login } = useAuth()
	const router = useRouter()
	const { t } = useTranslation()

	const authUser = () =>
		login('google').then(() => {
			router.push('/')
		})

	return (
		<div className='flex h-screen w-screen flex-col items-center justify-center'>
			<Image
				src='/images/cocoon-logo.svg'
				width={150}
				height={150}
				alt='cocoon space'
				className='mb-8'
				priority
			/>

			<h1 className='mb-4 font-bold text-4xl'>{t('login:title')}</h1>

			<Button
				data-testid='login-btn'
				onClick={() => authUser()}
			>
				<SvgGoogle className='mr-2 text-sm' />

				{t('login:signInWithGoogle')}
			</Button>
		</div>
	)
}

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
	props: {
		...(await serverSideTranslations(locale as string, BASE_I18N)),
	},
})
